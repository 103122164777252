import { VueCookieNext } from 'vue-cookie-next';

const ID_TOKEN_KEY = "_seip_token" as string;

export const getToken = (): string | null => {
  //return window.localStorage.getItem(ID_TOKEN_KEY);
  return VueCookieNext.getCookie(ID_TOKEN_KEY);
};

export const saveToken = (token: string): void => {
  //window.localStorage.setItem(ID_TOKEN_KEY, token);
  VueCookieNext.setCookie(ID_TOKEN_KEY, token);
};

export const destroyToken = (): void => {
  //window.localStorage.removeItem(ID_TOKEN_KEY);
  VueCookieNext.removeCookie(ID_TOKEN_KEY,{
    path: '/',
    domain: '.seip-fd.gov.bd'
  });
};

export default { getToken, saveToken, destroyToken };
